import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/constants';

const MapContainer = styled.div`
  border: 1px solid ${colors.grey};
  border-radius: 0.25rem;
  height: 25rem;
  width: 100%; /* Ensure it occupies the full width */
`;

function Map() {
  return (
    <MapContainer>
      <iframe
        title="Map"
        src={`https://www.openstreetmap.org/export/embed.html?bbox=-8.606404,41.174813,-8.602404,41.178813&layer=mapnik&marker=41.176813,-8.604404`}
        style={{
          border: 0,
          width: '100%',
          height: '100%',
          maxWidth: '100%'
        }}
        allowFullScreen
      />
    </MapContainer>
  );
}

export default Map;
