import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import GoogleMap from '../components/googlemap';
import Card from '../components/card';

import Mail from '../assets/svg/mail.svg';
import Map from '../assets/svg/map.svg';
import layoutStyle from '../styles/layout';
import { media, colors } from '../styles/constants';

const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${layoutStyle}
  @media (min-width: ${media.bigDesktop}px) {
    max-width: 80%;
  }

  h1 {
    padding-bottom: 2rem;
    font-size: 3rem;
    text-align: center;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;

    a {
      display: flex;
      flex-direction: column-reverse;

      .gatsby-image-wrapper {
        @media (max-width: ${media.phoneLandscape}px) {
          max-width: 300px;
        }

        max-width: 450px;
        width: 450px;
      }
    }
    a: visited {
      text-decoration: none;
      color: #1f4258;
    }

    a {
      text-decoration: none;
      color: #1f4258;
    }

    @media (min-width: ${media.phoneLandscape}px) {
      flex-direction: row;
    }

    ${Card} {
      z-index: 1;
      flex-direction: column;
      margin-top: -1rem;
      p {
        margin-bottom: 0.5rem;
      }
      width: 300px;

      @media (min-width: ${media.phoneLandscape}px) {
        flex: 1 0 55%;
        margin-top: 0;
        width: 450px;
      }
    }

    .gatsby-image-wrapper {
      min-height: 16.25rem;
      width: 95%;
      border-radius: 0.25rem;

      @media (min-width: ${media.phoneLandscape}px) {
        flex: 0 1 45%;
        max-width: 60%;
      }
    }

    &:last-child {
      margin-top: 2rem;

      @media (max-width: ${media.newsTextWidth}px) {
        align-self: center;
        a {
          margin-bottom: 2rem;
        }
      }

      flex-direction: column;

      @media (min-width: ${media.newsLength}px) {
        flex-direction: row;
        justify-content: space-around;
      }

      .gatsby-image-wrapper {
        @media (min-width: ${media.phoneLandscape}px) {
          margin-left: 0;
          margin-right: -1rem;
        }
      }
    }
  }
`;

const ContactContainer = styled.div`
  background-color: ${colors.background};

  > div {
    display: flex;
    ${layoutStyle}

    flex-direction: column;

    @media (min-width: ${media.tabletPortrait}px) {
      flex-direction: row;
    }
  }
`;

const Contacts = styled.address`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;

  @media (min-width: ${media.tabletPortrait}px) {
    padding-top: 0;
    padding-left: 3rem;
  }

  h3 {
    font-size: 2rem;
    padding-bottom: 1.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 2rem;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;

    color: ${colors.blue};
    transition: color 150ms ease-in-out;

    &:focus,
    &:hover {
      color: ${colors.lightBlue};
    }

    svg {
      min-width: 2rem;
      min-height: 3rem;
      margin-right: 1rem;
    }

    p,
    span {
      font-size: 1.25rem;
    }
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 10rem;
`;

function NewsPage({ location: { pathname } }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          mail
          gmaps
        }
      }
      azitekFirstNews: file(relativePath: { eq: "azitek-origin-story.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      beerKegs: file(relativePath: { eq: "beer_kegs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      azitekTeam: file(relativePath: { eq: "azitek_team_cropped.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      badgeAzitek: file(relativePath: { eq: "badge-azitek.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rhaq: allFile(filter: { base: { eq: "rhaq-azitek.pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <Layout padTop>
      <SEO title="News" pathname={pathname} />
      <NewsContainer>
        <h1>News</h1>
        <div className="link-wrapper">
          <Link
            activeClassName="active"
            to="/meeting-market-demands-aziteks-recognition-in-the-indoor-location-market"
          >
            <Card>
              <p>
                <strong>
                  Exploring how Azitek&#39;s technology is aligned with the expanding markets
                </strong>
              </p>
            </Card>
            <Img fluid={data.badgeAzitek.childImageSharp.fluid} />
          </Link>
          <Link
            activeClassName="active"
            to="/azitek-successfully-reaches-a-total-of-1m-euros-in-investment"
          >
            <Card>
              <p>
                <strong>Azitek Successfully Reaches a Total of </strong>€
                <strong>1M in Investment</strong>
              </p>
            </Card>
            <Img fluid={data.azitekTeam.childImageSharp.fluid} />
          </Link>
          <Link
            activeClassName="active"
            to="/the-impact-of-tracking-returnable-transport-items-in-modern-supply-chains"
          >
            <Card>
              <p>
                <strong>
                  The Impact of Tracking Returnable Transport Items in Modern
                  Supply Chains
                </strong>
              </p>
            </Card>
            <Img fluid={data.beerKegs.childImageSharp.fluid} />
          </Link>
          <Link
            activeClassName="active"
            to="/how-we-accidentally-became-tugger-train-efficiency-experts"
          >
            <Card>
              <p>
                <strong>
                  How We Accidentally Became Tugger Train Efficiency Experts -
                  Origin Story
                </strong>
              </p>
            </Card>
            <Img fluid={data.azitekFirstNews.childImageSharp.fluid} />
          </Link>
        </div>
      </NewsContainer>
      <ContactContainer>
        <div>
          <GoogleMap />
          <Contacts>
            <h3>Reach Us</h3>
            <a href={data.site.siteMetadata.gmaps} aria-label="Our Office">
              <Map />
              <Address>
                <p>UPTEC</p>
                <p>Rua Alfredo Allen, N.º455 3.16, 4200-135 </p>
                <p>Porto, Portugal</p>
              </Address>
            </a>
            <a
              href={`mailto:${data.site.siteMetadata.mail}`}
              aria-label="Mail Us"
            >
              <Mail />
              <span>{data.site.siteMetadata.mail}</span>
            </a>
          </Contacts>
        </div>
      </ContactContainer>
    </Layout>
  );
}

NewsPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default NewsPage;
